import { Component, ViewChild, OnInit } from '@angular/core';

import courseContent from './../../../assets/data/course-content/courseContent.json';

@Component({
  selector: 'app-courses',
  templateUrl: './courses.component.html',
  styleUrls: ['./courses.component.css']
})
export class CoursesComponent implements OnInit {
  courseReach = courseContent.image;
  courseStudents = courseContent.students;
  courseCountries = courseContent.countries;
  courseContent = courseContent.data;

  ngOnInit() {
    window.scrollTo(0, 0);
  }
}
