<app-header></app-header>
<div class="container mb-3">
  <h1 class="text-center">Courses</h1>
  <h3>
    One of the major objectives behind the Python Power Electronics project is to provide affordable and accessible education in the field of power engineering through online courses. More than {{courseStudents}} students from {{courseCountries}} countries all over the world have registered for these online courses.
  </h3>
  <img [src]="courseReach" alt="Course Reach">

  <mat-accordion
        class="example-headers-align"
        multi>
    <mat-expansion-panel
      *ngFor="let course of courseContent; index as courseIndex">
      <mat-expansion-panel-header>
        <mat-panel-description>
          {{course.title}}
        </mat-panel-description>
      </mat-expansion-panel-header>
      <ng-container *ngTemplateOutlet="matExpansionPanelContent">
      </ng-container>
      <mat-action-row>
        <span class="course-host">Hosted on:</span>
        <div *ngFor="let platform of course.platforms">
          <a class="btn btn-primary"
              href="{{ platform.link }}"
              target="_blank">
              {{ platform.name }}
          </a>
        </div>
    </mat-action-row>
    <ng-template #matExpansionPanelContent>
      <p class="course-abstract">{{course.description}}</p>
      <h4>Course level: {{course.level}}</h4>
    </ng-template>
    </mat-expansion-panel>
  </mat-accordion>

  <div class="pb-3"></div>
</div>
