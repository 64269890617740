<app-header></app-header>
<div class="container mb-3">
  <h1 class="text-center">Simulation Packages</h1>
  <h3>
    In case you need simulation files to get started with a project, I have hosted a number of simulation cases using Python Power Electronics on the eCommerce website Gumroad.
  </h3>

  <mat-accordion
        class="example-headers-align"
        multi>
    <mat-expansion-panel
      *ngFor="let item of commerceContent">
      <mat-expansion-panel-header>
        <mat-panel-description>
          {{item.title}}
        </mat-panel-description>
      </mat-expansion-panel-header>
      <ng-container *ngTemplateOutlet="matExpansionPanelContent">
      </ng-container>
      <mat-action-row>
        <a class="btn btn-primary"
            href="{{ item.link }}"
            target="_blank">
            View
        </a>
    </mat-action-row>
    <ng-template #matExpansionPanelContent>
      <p class="commerce-abstract">{{item.description}}</p>
    </ng-template>
    </mat-expansion-panel>
  </mat-accordion>

</div>
