import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { HomepageComponent } from './components/homepage/homepage.component';
import { CoursesComponent } from './components/courses/courses.component';
import { ManualTemplateComponent } from './components/manual-template/manual-template.component';

const routes: Routes = [
  {path: 'courses', component: CoursesComponent},
  {path: '', component: ManualTemplateComponent, pathMatch: 'full'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes,
      { relativeLinkResolution: 'legacy' }
    )],
  exports: [RouterModule]
})
export class AppRoutingModule { }
