import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { MatCardModule } from '@angular/material/card';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatTableModule } from '@angular/material/table';

import { AppComponent } from './app.component';
import { HomepageComponent } from './components/homepage/homepage.component';
import { HeaderComponent } from './components/page-sections/header/header.component';
import { ManualTemplateComponent } from './components/manual-template/manual-template.component';
import { ContentSlideComponent } from './components/content-slide/content-slide.component';
import { CoverPageComponent } from './components/cover-page/cover-page.component';
import { ContactPageComponent } from './components/contact-page/contact-page.component';
import { CoursesComponent } from './components/courses/courses.component';
import { VideosComponent } from './components/videos/videos.component';
import { PublicationComponent } from './components/publications/publications.component';
import { SoftwareDownloadsComponent } from './components/software-downloads/software-downloads.component';
import { CommerceComponent } from './components/commerce/commerce.component';

@NgModule({
  declarations: [
    AppComponent,
    HomepageComponent,
    HeaderComponent,
    ManualTemplateComponent,
    ContentSlideComponent,
    CoverPageComponent,
    ContactPageComponent,
    CoursesComponent,
    VideosComponent,
    PublicationComponent,
    SoftwareDownloadsComponent,
    CommerceComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    // Angular material
    MatCardModule,
    MatProgressBarModule,
    MatExpansionModule,
    MatTableModule,

    FontAwesomeModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
