import { Component, OnInit } from '@angular/core';

import softwareDownloadsContent from './../../../assets/data/software-downloads-content/softwareDownloadsContent.json';

@Component({
  selector: 'app-software-downloads',
  templateUrl: './software-downloads.component.html',
  styleUrls: ['./software-downloads.component.css']
})
export class SoftwareDownloadsComponent implements OnInit {

  softwareDownloadsContent = softwareDownloadsContent.data;
  contentList: any[];

  ngOnInit() {
    window.scrollTo(0, 0);
  }

  constructor() {
    let latestVersions: any[] = [];
    let webVersions: any[] = [];
    let cmdVersions: any[] = [];
    let webFound = false;
    let cmdFound = false;
    for (let i=0; i<this.softwareDownloadsContent.length; i++) {
      if (this.softwareDownloadsContent[i].type=='Web app') {
        if (!webFound) {
          latestVersions.push({...this.softwareDownloadsContent[i]});
          webFound = true;
        }
        webVersions.push({...this.softwareDownloadsContent[i]});
      }
      if (this.softwareDownloadsContent[i].type=='Command line') {
        if (!cmdFound) {
          latestVersions.push({...this.softwareDownloadsContent[i]});
          cmdFound = true;
        }
        cmdVersions.push({...this.softwareDownloadsContent[i]})
      }
    }
    this.contentList = [
      {
        title: 'Latest versions',
        releases: latestVersions
      },
      {
        title: 'All version of the web app',
        releases: webVersions
      },
      {
        title: 'All version of the command line app',
        releases: cmdVersions
      }
    ];
  }

}
