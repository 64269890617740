<div class="row mb-3">
  <div class="col-12 col-md-5">
    <div class="row">
      <div class="col-12">
        <div class="text-center">
          <img src="assets/img/linkedin_profile.jpg" alt="">
        </div>
      </div>
    </div>
    <div class="row text-center mt-3">
      <div class="col-12">
        <p>Dr. Shivkumar V. Iyer <br>
        PhD, Power Electronics <br>
        pythonpowerelectronics@gmail.com</p>
      </div>
    </div>
  </div>

  <div class="col-12 col-md-7">
      <div class="row">
        <div class="col-12">
          <h2>Professional networking</h2>
          <a href="https://www.linkedin.com/in/iyershivkumar/"
              target="_blank">
              <fa-icon [icon]="faLinkedinIn" size="2x"></fa-icon>
          </a>
          <a href="https://www.xing.com/profile/Shivkumar_Iyer3"
              target="_blank">
              <fa-icon [icon]="faXing" size="2x"></fa-icon>
          </a>
          <h2 class="mt-3">Social Media</h2>
          <a href="https://www.facebook.com/pythonpowerelectronics"
              target="_blank">
              <fa-icon [icon]="faFacebook" size="2x"></fa-icon>
          </a>
          <a href="https://www.youtube.com/c/PythonPowerElectronics"
              target="_blank">
              <fa-icon [icon]="faYoutube" size="2x"></fa-icon>
          </a>
          <a href="https://twitter.com/pythonpoweretrx"
              target="_blank">
              <fa-icon [icon]="faTwitter" size="2x"></fa-icon>
          </a>
          <a href="https://discord.gg/DUxq8nf"
              target="_blank">
              <fa-icon [icon]="faDiscord" size="2x"
                        title="Discord Server"></fa-icon>
          </a>
          <h2 class="mt-3">Online learning</h2>
          <a href="https://www.udemy.com/user/shivkumar-iyer/"
              target="_blank">
              <!-- <fa-icon [icon]="faUserGraduate" size="2x"></fa-icon> -->
              <span class="udemy-logo"></span>
          </a>
          <h2 class="mt-3">E-commerce</h2>
          <a href="https://gumroad.com/shivkiyer"
              target="_blank">
              <fa-icon [icon]="faShoppingCart" size="2x"
                        title="Gumroad"></fa-icon>
          </a>
        </div>
      </div>
  </div>

</div>
