<div class="slide-content"
      [@slideObject]="slideState"
      (@slideObject.done)="updateSlideVisibility()">
  <img [src]="slideContent.image"
        alt="Simulation course">

  <div class="row">
    <div class="col-12 col-md-10 mx-auto">
      <h2 class='mt-3'>{{ slideContent.description }}</h2>
    </div>
  </div>
</div>

<div class="row">
  <div class="col-12">
    <a *ngIf="slideState=='visible'"
            class="btn btn-primary float-right"
            (click)="navigateTo(slideContent.action)">
            Learn more
    </a>
  </div>
</div>

<div class="row mt-3 slide-progress">

  <div class="col-1 ml-auto">
    <div class="icon-box float-right"
          (click)="previousSlide()">
      <fa-icon [icon]="faChevronLeft" size="2x"></fa-icon>
    </div>
  </div>
  <div class="col-9 col-md-5 pt-2">
    <mat-progress-bar
      mode="determinate"
      class="float-center"
      [value]="slideProgress">
    </mat-progress-bar>
  </div>
  <div class="col-1 mr-auto">
    <div class="icon-box"
          (click)="nextSlide()">
      <fa-icon [icon]="faChevronRight" size="2x"></fa-icon>
    </div>
  </div>

</div>
