<app-cover-page></app-cover-page>

<div class="container mb-3">
  <mat-card class='animation-container'>
    <app-content-slide
        [contents]="learnContents">
    </app-content-slide>
  </mat-card>

  <mat-card class='animation-container'>
    <app-content-slide
        [contents]="useContents">
    </app-content-slide>
  </mat-card>

  <mat-card>
    <app-contact-page></app-contact-page>
  </mat-card>
</div>
