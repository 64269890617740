<app-header></app-header>
<div class="container mb-3">
  <h1 class="text-center">Publications</h1>
  <h3>
    I regularly publish books that are companion study material to the online courses. Along with books, there are short papers on a few selected topics in circuit simulation, control theory and general electrical engineering.
  </h3>

  <div *ngFor="let publication of publicationContent">

    <h2 class="mb-2 mt-3">{{publication.type}}</h2>

    <mat-accordion
          class="example-headers-align"
          multi>
      <mat-expansion-panel
        *ngFor="let content of publication.contentList">
        <mat-expansion-panel-header>
          <mat-panel-description>
            {{content.title}}
          </mat-panel-description>
        </mat-expansion-panel-header>
        <ng-container *ngTemplateOutlet="matExpansionPanelContent">
        </ng-container>
        <mat-action-row>
            <a class="btn btn-primary"
                href="{{ content.link }}"
                target="_blank">
                View
            </a>
        </mat-action-row>
        <ng-template #matExpansionPanelContent>
          <div class="row"
              *ngIf="content.publisher || content.date">
            <div class="col-12 col-md-6">
              <h4 *ngIf="content.date">{{content.date}}</h4>
            </div>
            <div class="col-12 col-md-6">
              <h4 *ngIf="content.publisher">{{content.publisher}}</h4>
            </div>
          </div>

          <p class="content-abstract">{{content.description}}</p>
          <ul *ngIf="content.features && content.features.length>0">
            <li *ngFor="let feature of content.features">{{feature}}</li>
          </ul>
        </ng-template>
      </mat-expansion-panel>
    </mat-accordion>

  </div>

</div>
