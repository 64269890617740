import { Component, OnInit } from '@angular/core';

import commerceContent from './../../../assets/data/commerce-content/commerceContent.json';

@Component({
  selector: 'app-commerce',
  templateUrl: './commerce.component.html',
  styleUrls: ['./commerce.component.css']
})
export class CommerceComponent implements OnInit {

  commerceContent = commerceContent.data;

  ngOnInit() {
    window.scrollTo(0, 0);
  }

}
