import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-manual-template',
  templateUrl: './manual-template.component.html'
})
export class ManualTemplateComponent implements OnInit, OnDestroy {
  pageContent: string = 'home';
  pageQuery: Subscription;

  constructor(private router: Router,
              private route: ActivatedRoute) {}

  ngOnInit() {
    this.pageQuery = this.route.queryParamMap.subscribe(
      queryParams => {
        let extractedQuery: string = '';
        if (queryParams.hasOwnProperty('params') && queryParams['params'].hasOwnProperty('page')) {
          extractedQuery = queryParams['params']['page'];
        }
        if (extractedQuery.trim().length == 0) {
          this.pageContent = 'home';
        } else {
          this.pageContent = extractedQuery;
        }
      }
    );
  }

  ngOnDestroy() {
    this.pageQuery.unsubscribe();
  }
}
