import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-cover-page',
  templateUrl: './cover-page.component.html',
  styleUrls: ['./cover-page.component.css']
})
export class CoverPageComponent {

  constructor(private router: Router) {
    console.log("cover page")
  }

  navigateTo(pageDest: String) {
    this.router.navigate(
       ['/'],
       {
         queryParams: {page: pageDest}
       }
     );
  }

}
