<nav class="navbar navbar-expand navbar-light fixed-top bg-light">
  <div class="container">
    <div class="collapse navbar-collapse" id="navbarText">
      <ul class="navbar-nav mr-auto">
        <li class="nav-item active">
          <a routerLink="/">
            <fa-icon [icon]="faHome" size="2x"></fa-icon>
          </a>
        </li>
      </ul>
      <ul class="navbar-nav ml-auto">
        <li class="nav-item">
          <fa-icon [icon]="faChevronUp" size="2x"
                  class="float-right"
                  (click)="goToTop()"></fa-icon>
        </li>
      </ul>
    </div>
  </div>
</nav>
