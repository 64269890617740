import { Component } from '@angular/core';

import { faLinkedinIn } from '@fortawesome/free-brands-svg-icons';
import { faXing } from '@fortawesome/free-brands-svg-icons';
import { faFacebook } from '@fortawesome/free-brands-svg-icons';
import { faYoutube } from '@fortawesome/free-brands-svg-icons';
import { faTwitter } from '@fortawesome/free-brands-svg-icons';
import { faDiscord } from '@fortawesome/free-brands-svg-icons';
import { faUserGraduate } from '@fortawesome/free-solid-svg-icons';
import { faShoppingCart } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-contact-page',
  templateUrl: './contact-page.component.html',
  styleUrls: ['./contact-page.component.css']
})
export class ContactPageComponent {
  faLinkedinIn = faLinkedinIn;
  faXing = faXing;
  faFacebook = faFacebook;
  faYoutube = faYoutube;
  faTwitter = faTwitter;
  faUserGraduate = faUserGraduate;
  faShoppingCart = faShoppingCart;
  faDiscord = faDiscord;
}
