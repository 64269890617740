<app-header></app-header>
<div class="container mb-4">
  <h1 class="text-center">Videos</h1>
  <h3>
    As a part of the project, regular educational content in the form of short videos on various topics arranged as playlists are released on YouTube. Browse through the lists of videos arranged in chronological order and check out code-along sessions.
  </h3>

  <mat-accordion multi>
    <mat-expansion-panel
      *ngFor="let playlist of videoContent">
      <mat-expansion-panel-header>
        <mat-panel-description>
          {{playlist.title}}
        </mat-panel-description>
      </mat-expansion-panel-header>
      <ng-container *ngTemplateOutlet="playListContent">
      </ng-container>
      <ng-template #playListContent>
        <div class="row mb-2 mt-2">
          <div class="col-12 col-md-8">
            <p class="playlist-abstract">{{playlist.description}}</p>
          </div>
          <div class="col-12 col-md-4">
            <a class="btn btn-primary"
                href="{{playlist.link}}"
                target="_blank">
                Go to playlist
            </a>
          </div>
        </div>

        <mat-accordion multi>
          <mat-expansion-panel
            class="course-panel"
            *ngFor="let video of playlist.videos">
            <mat-expansion-panel-header>
              <mat-panel-description>
                {{video.title}}
              </mat-panel-description>
            </mat-expansion-panel-header>
            <ng-container *ngTemplateOutlet="videoContent">
            </ng-container>
            <mat-action-row>
                <a class="btn btn-primary"
                    href="{{video.link}}"
                    target="_blank">
                    Go to video
                </a>
            </mat-action-row>
            <ng-template #videoContent>
              <p class="video-abstract">{{video.description}}</p>
            </ng-template>
          </mat-expansion-panel>
        </mat-accordion>
      </ng-template>
    </mat-expansion-panel>
  </mat-accordion>

</div>
