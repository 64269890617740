import { Component,
          Input,
          OnInit,
          OnDestroy,
          DoCheck } from '@angular/core';

import { trigger,
          animate,
          style,
          transition,
          state } from '@angular/animations';
import { Router } from '@angular/router';

import { MatProgressBarModule } from '@angular/material/progress-bar';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-content-slide',
  templateUrl: './content-slide.component.html',
  styleUrls: ['./content-slide.component.css'],
  animations: [
    trigger('slideObject', [
      state('visible', style({
        opacity: 1,
        transform: 'translateX(0)'
      })),
      state('fadeaway', style({
        opacity: 0,
        transform: 'translateX(400px)'
      })),
      state('fadein', style({
        opacity: 0,
        transform: 'translateX(-200px)'
      })),
      transition('visible <=> fadeaway', animate(500)),
      transition('visible <=> fadein', animate(500))
    ])
  ]
})
export class ContentSlideComponent implements OnInit, OnDestroy, DoCheck {
 faChevronLeft = faChevronLeft;
 faChevronRight = faChevronRight;

 allContent: any;
 slideIndex: number = 0;
 slideContent: any;
 slideProgress: number = 0;
 slideLoopTimer: any;

 slideState: string = 'visible';
 slideRightMovement: boolean = true;

 @Input() contents: any;

 constructor(private router: Router) {}

 ngOnInit() {
   this.allContent = [...this.contents.data];
   this.updateSlideContent();
   this.loopingSlide();
 }

 ngOnDestroy() {
   clearInterval(this.slideLoopTimer);
 }

 ngDoCheck() {
   if (this.slideRightMovement) {
     if (this.slideState == 'start') {
       this.slideState = 'fadeaway';
     } else if (this.slideState == 'fadeaway') {
       this.slideState = 'fadein';
     } else if (this.slideState == 'fadein') {
       this.updateSlideContent();
       this.slideState = 'visible';
     }
   } else {
     if (this.slideState == 'start') {
       this.slideState = 'fadein';
     } else if (this.slideState == 'fadein') {
       this.updateSlideContent();
       this.slideState = 'fadeaway';
     } else if (this.slideState == 'fadeaway') {
       this.slideState = 'visible';
     }
   }
 }

 loopingSlide() {
   this.slideLoopTimer = window.setInterval(() => {
     if (this.slideRightMovement) {
       this.nextSlide();
     } else {
       this.previousSlide();
     }
   }, 5000 + Math.floor(Math.random()*2000));
 }

 incrementSlide(incr:boolean = true) {
   if (incr) {
     this.slideRightMovement = true;
   } else {
     this.slideRightMovement = false;
   }
 }

 updateSlideVisibility() {}

 updateSlideContent() {
   this.slideContent = this.allContent[this.slideIndex];
   this.slideProgress = (((this.slideIndex+1)/this.allContent.length) *100)-5;
 }

 nextSlide() {
   this.slideIndex++;
   if (this.slideIndex >= this.allContent.length) {
     this.slideIndex = 0;
   }
   this.slideRightMovement = true;
   this.slideState = 'start';
 }

 previousSlide() {
   this.slideIndex--;
   if (this.slideIndex < 0) {
     this.slideIndex = this.allContent.length - 1;
   }
  this.slideRightMovement = false;
   this.slideState = 'start';
 }

 navigateTo(pageDest: string) {
   this.router.navigate(
      ['/'],
      {
        queryParams: {page: pageDest}
      }
    );
 }

}
