<div class="cover-page">
  <div class="cover-page-image"></div>
  <h3 class="text-center">Welcome to</h3>
  <h1 class="text-center">
    PYTHON POWER ELECTRONICS!
  </h1>

  <div class="row mt-3">
    <div class="col-12 col-md-8 mx-auto">
      <p>This project combines the development of a free and open source circuit simulator for power electronics with accessible education in the field of electrical engineering.</p>

      <!-- <p class="mt-3">Use the animated sections below to learn about courses, online videos, books and case studies to get started with your journey in using open source software in electrical engineering.</p> -->

    </div>
  </div>

  <div class="row mt-3">
    <div class="col-6">
      <a class="btn btn-primary float-right mr-3 cover-buttons"
          (click)="navigateTo('softwaredownloads')">
        Download
      </a>
    </div>

    <div class="col-6">
      <a class="btn btn-primary ml-3 cover-buttons"
          (click)="navigateTo('courses')">
        Learn
      </a>
    </div>
  </div>

  <div class="row mt-3">
    <div class="col-6">
      <a class="btn btn-primary float-right mr-3 cover-buttons"
          (click)="navigateTo('videos')">
        Watch
      </a>
    </div>

    <div class="col-6">
      <a class="btn btn-primary ml-3 cover-buttons"
          (click)="navigateTo('publications')">
        Read
      </a>
    </div>
  </div>


</div>
