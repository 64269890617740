<app-header></app-header>
<div class="container mb-4">
  <h1 class="text-center">Downloads</h1>
  <h3>
    Python Power Electronics is a free and open source circuit simulator for power engineering applications and is available as a web based application and a command line application. The web based application requires the installation of a Django based server following which the simulation can be run from a web browser which makes simulation easier and is therefore the recommended approach. The command line application involves execution of Python commands in a command line and is recommended for advanced users.
  </h3>

  <div *ngFor="let content of contentList">
    <h2 class="mt-5 mb-2">{{content.title}}</h2>
    <mat-accordion
          class="example-headers-align"
          multi>
      <mat-expansion-panel
        *ngFor="let item of content.releases">
        <mat-expansion-panel-header>
          <mat-panel-title>
            Version {{item.version}}
          </mat-panel-title>
          <mat-panel-description>
            {{item.type}}
          </mat-panel-description>
        </mat-expansion-panel-header>
        <ng-container *ngTemplateOutlet="matExpansionPanelContent">
        </ng-container>
        <mat-action-row>
            <a class="btn btn-primary"
                href="{{ item.ziplink }}"
                target="_blank">
                .zip
            </a>
            <a class="btn btn-primary"
                href="{{ item.tarlink }}"
                target="_blank">
                .tar.gz
            </a>
        </mat-action-row>
        <ng-template #matExpansionPanelContent>
          <div class="row">
            <div class="col-6">
              {{item.date}}
            </div>
            <div class="col-6">
              Based on Python {{item.python}}
            </div>
          </div>
        </ng-template>
      </mat-expansion-panel>
    </mat-accordion>
  </div>

</div>
