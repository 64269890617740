import { Component } from '@angular/core';

import { MatCardModule } from '@angular/material/card';

import learningSlides from './../../../assets/data/learn-content/learnContent.json';
import usingSlides from './../../../assets/data/use-content/useContent.json';

@Component({
  selector: 'app-home-page',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.css']
})
export class HomepageComponent {

  learnContents: any;
  useContents: any;

  constructor() {
    this.learnContents = learningSlides;
    this.useContents = usingSlides;
  }

}
