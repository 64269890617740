import { Component, OnInit } from '@angular/core';

import { MatAccordion } from '@angular/material/expansion';
import { MatExpansionModule } from '@angular/material/expansion';

import videoContent from './../../../assets/data/video-content/videoContent.json';

@Component({
  selector: 'app-videos',
  templateUrl: './videos.component.html',
  styleUrls: ['./videos.component.css']
})
export class VideosComponent implements OnInit {

  videoContent = videoContent.data;

  ngOnInit() {
    window.scrollTo(0, 0);
  }

}
