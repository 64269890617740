import { Component, OnInit } from '@angular/core';

import publicationContent from './../../../assets/data/publication-content/publicationContent.json';

@Component({
  selector: 'app-publications',
  templateUrl: './publications.component.html',
  styleUrls: ['./publications.component.css']
})
export class PublicationComponent implements OnInit {

  publicationContent = publicationContent.data;

  ngOnInit() {
    window.scrollTo(0, 0);
  }

}
